import React, { createRef, useEffect, useMemo, useState, useRef, useCallback} from 'react';
import { useHistory } from "react-router-dom";
import { NumberBox, TextBox, Validator } from "devextreme-react";
import Form, { GroupItem, Label, RequiredRule, NumericRule, SimpleItem, PatternRule } from "devextreme-react/form";
import "devextreme-react/text-area";
import { Tooltip } from "devextreme-react/tooltip";
import notify from "devextreme/ui/notify";
import PropTypes from "prop-types";

import { useProgram } from '../../programs'
import { createFarmer, fetchFarmerById, updateFarmer } from "../../api/farmers";
import { Button, Select, FileUploader, Spinner } from "../../components";
import { useLogin } from "../../cookies";
import countries from "../../utils/countries.json";
import { processNullValues, toBase64 } from "../../utils/utils";
import { getFarmerEditPath, getFarmersPath } from '../../app-routes'

const genderOptions = ["Male", "Female", "Other"];
const ownershipOptions = ["Owner", "Tenant"];
const relativeOptions = ["Child", "Grandchild", "Parent", "Grandparent", "Sibling", "Uncle/Haunt", "Cousin", "Other"];

const countryOptions = countries.map((country) => ({
  name: country.name,
  value: country.code
}));
const customGenderOptions = genderOptions.map((option) => ({
  name: option,
  value: option.charAt(0),
}));
const customOwnershipOptions = ownershipOptions.map((option) => ({
  name: option,
  value: option
}));

const validationRules = {
  firstName: [{ type: "required", message: "Firstname is required." }],
  lastName: [{ type: "required", message: "Lastname is required." }],
  preferredLanguage: [
    { type: "required", message: "Preferred language is required." },
  ],
  relativeType: [{ type: "required", message: "Relative type is required." }],
  gender: [{ type: "required", message: "Gender is required." }],
  yearOfBirth: [
    { type: "required", message: "Year of birth is required." },
    { type: "numeric", message: "Year of birth is an number." },
  ],
  averageWeeklyHoursOfWorkInFarm: [
    { type: "numeric", message: "Value should be a number." },
  ],
  relativeAverageWeeklyHoursInFarm: [
    { type: "numeric", message: "Value should be a number." }
  ],
  otherFamilyMembersTotalWeeklyHoursOfWorkInFarm: [
    { type: "numeric", message: "Value should be a number." }
  ],
  phoneNumber: [
    { type: "required", message: "Mobile Phone Number is required." },
  ],
  district : [
    { type: "required", message: "District is required." },
  ],
  subdistrict : [
    { type: "required", message: "Subdistrict is required." },
  ],
};

export const getBaseEditorOptions = (isReadOnly) => {
  return {
    disabled: isReadOnly,
    stylingMode: "outlined",
  };
};

const BioData = ({ isEditMode, isEditing, onSubmit, farmerId }) => {
  const program = useProgram();
  const history = useHistory();
  const { token } = useLogin();

  const initialBioDataValues = useMemo(() => ({
    firstName: null,
    lastName: null,
    nationality: program?.configs?.country,
    preferredLanguage: program?.configs?.defaultLanguage,
    gender: null,
    yearOfBirth: null,
    proofOfId: null,
    photo: null,
    profession: null,
    postalAddress: null,
    zipCode: null,
    city: null,
    subdistrict: null,
    district: null,
    email: null,
    smsOptedout: null,
    country: program?.configs?.country,
    phoneNumber: null,
    relationshipToFarm: null,
    distanceToTrainingCenter: null,
    partnerFirstName: null,
    partnerGender: null,
    partnerYearOfBirth: null,
    relatives: [
      {
        relativeType: null,
        firstName: null,
        gender: null,
        yearOfBirth: null,
        averageWeeklyHoursOfWorkInFarm: null,
      },
    ],
    participantCode: null,
    accountDisabled: false,
    accountExcludedFromMonitoring: false,
    partnerAverageWeeklyHoursOfWorkInFarm: null,
    otherFamilyMembersTotalWeeklyHoursOfWorkInFarm: null,
    nationalIdNumber: null,
  }), [program]);

  const [loading, setLoading] = useState(false);
  const [bioData, setBioData] = useState(initialBioDataValues);
  const formRef = createRef();
  const isMountedFetchFarmer = useRef(false);

  const fetchFarmer = useCallback(async () => {
    const res = await fetchFarmerById(token, farmerId);
    if (res.isOk) {
      ["firstAccessDate", "lastAccessDate"].forEach(
        Reflect.deleteProperty.bind(null, res.data)
      );
      setBioData(res.data);
      setLoading(false);
    } else {
      notify("Failed to fetch farmer", "error", 2000);
    }
  }, [token, farmerId]);

  useEffect(() => {
    if (isEditMode && isMountedFetchFarmer.current === false) {
      fetchFarmer();
      isMountedFetchFarmer.current = true
    }
  }, [isEditMode, fetchFarmer, isMountedFetchFarmer]);

  const isReadOnly = isEditMode && !isEditing;

  const districtOptions = useMemo(() => program?.configs?.districts?.map((district) => ({
      name: district.name,
      value: district.name
    })).sort((e1, e2) => e1.name.localeCompare(e2.name))
  , [program]);

  const subdistrictOptions = useMemo(() => program?.configs?.subdistricts?.map((subdistrict) => ({
      name: subdistrict.name,
      value: subdistrict.name
    })).sort((e1, e2) => e1.name.localeCompare(e2.name))
    , [program]);

  const customLanguageOptions = useMemo(() => program?.configs?.languages?.map((language) => ({
      name: language.name,
      value: language.code
    })), [program]);

  const getYearOfBirthOptions = useMemo(() => {
    let maxOffset = 100;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let i = 0; i <= maxOffset; i++) {
      allYears.push({ name: thisYear - i, value: thisYear - i })
    }
    return allYears;
  }, []);

  const handleFileChange = async (key, file) => {
    const base64String = file && await toBase64(file)
    const updatedValues = {
      ...bioData,
      [key]: base64String,
    };
    setBioData(updatedValues);
  }

  const handleAddRelative = () => {
    const updatedValues = {
      ...bioData,
      relatives: bioData.relatives.concat({
        relativeType: null,
        firstName: null,
        gender: null,
        yearOfBirth: null,
        averageWeeklyHoursOfWorkInFarm: null,
      }),
    };
    setBioData(updatedValues);
  };

  const renderSelect = (values) => {
    const onValueChanged = (props) => {
      setBioData({
        ...bioData,
        [values.dataField]: props.value,
      });
    };

    switch (values.dataField) {
      case "gender":
        return (
          <Select
            id="gender"
            options={customGenderOptions}
            value={bioData.gender}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select the Participant's gender"
          />
        );
      case "partnerGender":
        return (
          <Select
            id="partnerGender"
            options={customGenderOptions}
            value={bioData.partnerGender}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select the Participant's partner gender"
          />
        );
      case "preferredLanguage":
        return (
          <Select
            id="preferredLanguage"
            options={customLanguageOptions}
            value={bioData.preferredLanguage}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select the Participant's preferred language"
          />
        );
      case "nationality":
        return (
          <Select
            id="nationality"
            options={countryOptions}
            value={bioData.nationality}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select the Participant's nationality"
          />
        );
      case "district":
        return (
          <Select
            id="district"
            options={districtOptions}
            value={bioData.district}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select a district"
          >
              <Validator validationGroup={"bioDataGroup"}>
              <RequiredRule message="District is required" />
              </Validator>
          </Select>
        );
      case "subdistrict":
        return (
          <Select
            id="subdistrict"
            options={subdistrictOptions}
            value={bioData.subdistrict}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select a subdistrict">
            <Validator validationGroup={"bioDataGroup"}>
                      <RequiredRule message="Subdistrict is required" />
                    </Validator>
            </Select>
        );
      case "country":
        return (
          <Select
            id="country"
            options={countryOptions}
            value={bioData.country}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select a country"
          />
        );
      case "yearOfBirth":
        return (
          <Select
            id="yearOfBirth"
            options={getYearOfBirthOptions}
            value={bioData.yearOfBirth}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select the year of birth"
          />
        );
      case "partnerYearOfBirth":
        return (
          <Select
            id="partnerYearOfBirth"
            options={getYearOfBirthOptions}
            value={bioData.partnerYearOfBirth}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="Select the year of birth"
          />
        );
      case "relationshipToFarm":
        return (
          <Select
            id="relationshipToFarm"
            options={customOwnershipOptions}
            value={bioData.relationshipToFarm}
            onChange={onValueChanged}
            disabled={isReadOnly}
            tooltip="The Participant's situation with respect to the farm"
          />
        );


      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (isEditMode) {
      const res = await updateFarmer(token, farmerId, processNullValues({...bioData, accountExcludedFromMonitoring : bioData.accountExcludedFromMonitoring ? 1 : 0}));
      if (res.isOk) {
        if (bioData.photo != null) bioData.photoFilename = bioData.userId + '.' + bioData.photo.substring(bioData.photo.indexOf('/') + 1, bioData.photo.indexOf(';'));
        else bioData.photoFilename = null;
        if (bioData.proofOfId != null) bioData.proofOfIdFilename = bioData.userId + '.' + bioData.proofOfId.substring(bioData.proofOfId.indexOf('/') + 1, bioData.proofOfId.indexOf(';'));
        else bioData.proofOfIdFilename = null;
        notify(
          `${bioData.firstName} ${bioData.lastName} farmer was successfully updated`,
          "success",
          2000
        );
        onSubmit();
      } else {
        notify(
          `Failed to update ${bioData.firstName} ${bioData.lastName} bio data`,
          "error",
          2000
        );
      }
    } else {
      const res = await createFarmer(token, {
        ...bioData,
        accountExcludedFromMonitoring : bioData.accountExcludedFromMonitoring ? 1 : 0, 
        userId: farmerId,
        yearOfBirth: parseInt(bioData.yearOfBirth),
      });
      if (res.isOk) {
        const resetValues = {
          ...initialBioDataValues,
          relatives: [
            {
              relativeType: null,
              firstName: null,
              gender: null,
              yearOfBirth: null,
              averageWeeklyHoursOfWorkInFarm: null,
            },
          ],
        };
        setBioData(resetValues);
        history.push(getFarmerEditPath(program.name,res.data.userId));
        notify(
          `${bioData.firstName} ${bioData.lastName} farmer was successfully created`,
          "success",
          2000
        );
      } else {
        notify(`Failed to create a participant`, "error", 2000);
      }
    }

    setLoading(false);
  };

  const handleCancel = () => {
    if (isEditMode) {
      onSubmit();
    } else {
      history.push(getFarmersPath(program.name));
    }
  };

  // Used to find the type of broken rule
  function findType(array, value) {
    return  array.find(element => element.type === value);
  }

  const validateGroup = async (e) => {
    const res = await e.validationGroup.validate();
    if (res.brokenRules.length > 0) {
      // Find the type of broken rule
      const isRequired = findType(res.brokenRules, "required");
      const isInvalidFormat = findType(res.brokenRules, "pattern");
      // Specify error message
      if(isRequired){
        notify("Please fill in all required details", "error", 4000);
      }else if(isInvalidFormat){
        notify("Invalid format for some fields", "error", 4000);
      }
    } else {
      await handleSubmit();
    }
  };

  const handleRemoveRelative = (indexToRemove) => {
    const updatedValues = {
      ...bioData,
      relatives: bioData.relatives.filter((child, index) => {
        return index !== indexToRemove;
      }),
    };
    setBioData(updatedValues);
  };

  return (
    <>
    {
      // If we edit the farmer, show the form with his data ready (isEditMode)
      // If we create th farmer show the form empty (!isEditMode)
    (isEditMode && bioData.firstName ) || !isEditMode ? 
          <Form formData={bioData} validationGroup={"bioDataGroup"} ref={formRef}>
          <GroupItem cssClass="first-group" caption="Personal details">
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="firstName"
                validationRules={validationRules.firstName}
                render={() => (
                  <div>
                    <TextBox
                      id="firstName"
                      value={bioData.firstName}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, firstName: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                      <Validator validationGroup={"bioDataGroup"}>
                        <RequiredRule message="First Name is required." />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#firstName"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>First name of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="lastName"
                validationRules={validationRules.lastName}
                render={() => (
                  <div>
                    <TextBox
                      id="lastName"
                      value={bioData.lastName}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, lastName: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                      <Validator validationGroup={"bioDataGroup"}>
                        <RequiredRule message="Last Name is required." />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#lastName"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Last name of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="gender"
                render={renderSelect}
                validationRules={validationRules.gender}
              />
              <SimpleItem
                dataField="yearOfBirth"
                render={renderSelect}
                validationRules={validationRules.yearOfBirth}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="nationality"
                render={renderSelect}
              />
              <SimpleItem
                dataField="preferredLanguage"
                render={renderSelect}
                validationRules={validationRules.preferredLanguage}
              />
            </GroupItem>
            <GroupItem colCount={2}>
            <SimpleItem
                dataField="National Id Number"
                render={() => (
                  <div>
                    <TextBox
                      id="nationalIdNumber"
                      value={bioData.nationalIdNumber}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, nationalIdNumber: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                    </TextBox>
                    <Tooltip
                      target="#nationalIdNumber"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>National ID Number of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
            <SimpleItem dataField="proofOfId">
              <FileUploader
                file={bioData.proofOfId}
                label="proof of ID"
                isEditable={isEditing || !isEditMode}
                onFileChange={(value) => handleFileChange("proofOfId", value)}
                acceptPdf={false}
              />
            </SimpleItem>
            </GroupItem>
            <SimpleItem dataField="photo">
              <FileUploader
                file={bioData.photo}
                label="profile photo"
                isEditable={isEditing || !isEditMode}
                onFileChange={(value) => handleFileChange("photo", value)}
                acceptPdf={false}
              />
            </SimpleItem>
            <SimpleItem
              dataField="postalAddress"
              render={() => (
                <div>
                  <TextBox
                    id="postalAddress"
                    value={bioData.postalAddress}
                    onValueChange={(value) =>
                      setBioData({ ...bioData, postalAddress: value })
                    }
                    stylingMode="outlined"
                    disabled={isReadOnly}
                  ></TextBox>
                  <Tooltip
                    target="#postalAddress"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Postal Address of the participant</div>
                  </Tooltip>
                </div>
              )}
            />
            <GroupItem colCount={3}>
              <SimpleItem
                dataField="city"
                render={() => (
                  <div>
                    <TextBox
                      id="city"
                      value={bioData.city}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, city: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    ></TextBox>
                    <Tooltip
                      target="#city"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>City or Village of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="subdistrict"
                render={renderSelect}
                validationRules={validationRules.subdistrict}
              />
              <SimpleItem
                dataField="district"
                render={renderSelect}
                validationRules={validationRules.district}
              />
              <SimpleItem
                dataField="zipCode"
                render={() => (
                  <div>
                    <TextBox
                      id="zipCode"
                      value={bioData.zipCode}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, zipCode: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    ></TextBox>
                    <Tooltip
                      target="#zipCode"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>zipcode of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="country"
                render={renderSelect}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="phoneNumber"
                validationRules={validationRules.phoneNumber}
                render={() => (
                  <div>
                    <TextBox
                      id="phoneNumber"
                      value={bioData.phoneNumber}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, phoneNumber: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                      <Validator validationGroup={"bioDataGroup"}>
                        <RequiredRule message="Phone Number is required." />
                        <PatternRule
                          message="Phone number should be in the format +[country code][number], must not inlude hyphens, must not exceed 15 digits."
                          /* eslint-disable-next-line */
                          pattern={"^\\+[1-9]\\d{1,14}$"} />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      target="#phoneNumber"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Phone Number of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="smsOptedout"
                editorType="dxSwitch"
                editorOptions={{ disabled: isReadOnly }}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="email"
                render={() => (
                  <div>
                    <TextBox
                      id="email"
                      value={bioData.email}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, email: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                    </TextBox>
                    <Tooltip
                      target="#email"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Email of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
            <GroupItem colCount={3}>
              <SimpleItem
                dataField="profession"
                render={() => (
                  <div>
                    <TextBox
                      id="profession"
                      value={bioData.profession}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, profession: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    ></TextBox>
                    <Tooltip
                      target="#profession"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Profession of the participant</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="relationshipToFarm"
                render={renderSelect}
              />
              <SimpleItem
                dataField="distanceToTrainingCenter"
                render={() => (
                  <div>
                    <NumberBox
                      id="distanceToTrainingCenter"
                      value={bioData.distanceToTrainingCenter}
                      onValueChange={(value) =>
                        setBioData({
                          ...bioData,
                          distanceToTrainingCenter: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                      <Validator validationGroup={"bioDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </NumberBox>
                    <Tooltip
                      target="#distanceToTrainingCenter"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Distance to the SUTTI Ttraining Center, in kilometers</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem cssClass="second-group" caption="Household details">
            <GroupItem colCount={3}>
              <SimpleItem
                dataField="partnerFirstName"
                render={() => (
                  <div>
                    <TextBox
                      id="partnerFirstName"
                      value={bioData.partnerFirstName}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, partnerFirstName: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    ></TextBox>
                    <Tooltip
                      target="#partnerFirstName"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>FirstName of the Participant's Partner</div>
                    </Tooltip>
                  </div>
                )}
              />
              <SimpleItem
                dataField="partnerGender"
                render={renderSelect}
              />
              <SimpleItem
                dataField="partnerYearOfBirth"
                render={renderSelect}
              />
  
              <SimpleItem
                dataField="partnerAverageWeeklyHoursOfWorkInFarm"
                render={() => (
                  <div>
                    <NumberBox
                      id="partnerAverageWeeklyHoursOfWorkInFarm"
                      value={bioData.partnerAverageWeeklyHoursOfWorkInFarm}
                      onValueChange={(value) =>
                        setBioData({ ...bioData, partnerAverageWeeklyHoursOfWorkInFarm: value })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    ></NumberBox>
                    <Tooltip
                      target="#partnerAverageWeeklyHoursOfWorkInFarm"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Average of weekly hours the Participant's Partner works within the Participant's farm</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
            <GroupItem name={"relatives"}>
              {bioData.relatives &&
                bioData.relatives.map((child, index) => (
                  <GroupItem colCount={5} key={index}>
                    <SimpleItem
                      key={`relatives${index}.relativeType`}
                      dataField={`relatives[${index}].relativeType`}
                      editorType="dxSelectBox"
                      editorOptions={{
                        placeholder: "indicate relative type",
                        searchEnabled: true,
                        showClearButton: true,
                        dataSource: relativeOptions,
                        ...getBaseEditorOptions(isReadOnly)
                      }}
                      validationRules={validationRules.relativeType}
                    >
                      <Label text={`Relative ${index + 1} type`} />
                    </SimpleItem>
                    <SimpleItem
                      key={`relatives${index}.firstName`}
                      dataField={`relatives[${index}].firstName`}
                      editorOptions={{
                        placeholder: "indicate relative name",
                        ...getBaseEditorOptions(isReadOnly)
                      }}
                      validationRules={validationRules.firstName}
  
                    >
                      <Label text={`Relative ${index + 1} first name`} />
                    </SimpleItem>
                    <SimpleItem
                      key={`relatives${index}.gender`}
                      dataField={`relatives[${index}].gender`}
                      editorType="dxSelectBox"
                      editorOptions={{
                        placeholder: "indicate relative gender",
                        searchEnabled: true,
                        showClearButton: true,
                        dataSource: customGenderOptions,
                        displayExpr: "name",
                        valueExpr: "value",
                        ...getBaseEditorOptions(isReadOnly)
                      }}
                    >
                      <Label text={`Relative ${index + 1} gender`} />
                    </SimpleItem>
                    <SimpleItem
                      key={`relatives${index}.yearOfBirth`}
                      dataField={`relatives[${index}].yearOfBirth`}
                      editorType="dxSelectBox"
                      editorOptions={{
                        placeholder: "indicate relative year of birth",
                        searchEnabled: true,
                        showClearButton: true,
                        dataSource: getYearOfBirthOptions,
                        displayExpr: "name",
                        valueExpr: "value",
                        ...getBaseEditorOptions(isReadOnly)
                      }}>
                      <Label text={`Relative ${index + 1} year of birth`} />
                    </SimpleItem>
                    <SimpleItem
  
                      key={`siblinsg${index}.averageWeeklyHoursOfWorkInFarm`}
                      dataField={`relatives[${index}].averageWeeklyHoursOfWorkInFarm`}
                      editorType={"dxNumberBox"}
                      editorOptions={{
                        placeholder: "indicate relative average weekly hours",
                        ...getBaseEditorOptions(isReadOnly)
                      }}
                      validationRules={validationRules.relativeAverageWeeklyHoursInFarm}
                    >
  
                      <Label
                        text={`Relative ${index + 1
                          } average weekly hours of work in farm`}
                      />
                    </SimpleItem>
                    <SimpleItem
                      render={() => (
                        <Button
                          text={"Remove relative"}
                          onClick={() => handleRemoveRelative(index)}
                          disabled={isReadOnly}
                        />
                      )}
                    />
                  </GroupItem>
                ))}
              <SimpleItem
                render={() => (
                  <Button
                    text={"Add relative"}
                    onClick={handleAddRelative}
                    disabled={isReadOnly}
                  />
                )}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="otherFamilyMembersTotalWeeklyHoursOfWorkInFarm"
                render={() => (
                  <div>
                    <NumberBox
                      id="otherFamilyMembersTotalWeeklyHoursOfWorkInFarm"
                      value={bioData.otherFamilyMembersTotalWeeklyHoursOfWorkInFarm}
                      onValueChange={(value) =>
                        setBioData({
                          ...bioData,
                          otherFamilyMembersTotalWeeklyHoursOfWorkInFarm: value,
                        })
                      }
                      stylingMode="outlined"
                      disabled={isReadOnly}
                    >
                      <Validator validationGroup={"bioDataGroup"}>
                        <NumericRule />
                      </Validator>
                    </NumberBox>
                    <Tooltip
                      target="#otherFamilyMembersTotalWeeklyHoursOfWorkInFarm"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      closeOnOutsideClick={false}
                    >
                      <div>Other Family Members Total Weekly Hours Of Work In Farm</div>
                    </Tooltip>
                  </div>
                )}
              />
            </GroupItem>
          </GroupItem>
          {isEditMode && (
            <GroupItem cssClass="second-group" caption="User Account details">
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField="participantCode"
                  editorOptions={{
                    disabled: true,
                    stylingMode: "outlined",
                  }}
                />
              </GroupItem>
              <SimpleItem>
                <Label
                  text="WARNING ! If you toggle the below button,
                the user will no longer be able to log in and he will no longer appear in the participant list"
                />
              </SimpleItem>
              <SimpleItem
            dataField="accountDisabled"
            editorType="dxSwitch"
            editorOptions={{
              value: bioData.accountDisabled,
              disabled: isReadOnly, 
              onValueChanged: (e) => {
                setBioData((prevBioData) => ({
                  ...prevBioData,
                  accountDisabled: e.value
                }));
              }
            }}
          />
              <SimpleItem
                dataField="accountExcludedFromMonitoring"
                editorType="dxSwitch"
                editorOptions={{ disabled: isReadOnly }}
              />
            </GroupItem>
          )}
        </Form>
        : <Spinner/>} 

      {(isEditing || !isEditMode) && (
        <div className={"buttons"}>
          <Button
            text="Validate"
            validationGroup={"bioDataGroup"}
            onClick={validateGroup}
            loading={loading}
            useSubmitBehavior={true}
          />
          <Button text="Cancel" onClick={handleCancel} buttonStyle={"secondary"}  />
        </div>
      )}
    </>
  );
};

export default BioData;

BioData.propTypes = {
  isEditMode: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  farmerId: PropTypes.string,
};
